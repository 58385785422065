import { Routes } from '@angular/router';

export const routes: Routes = [
  /* { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'store',
    loadChildren: () =>
      import('./modules/store/store.module').then((m) => m.StoreModule),
  },
  {
    path: 'products',
    loadChildren: () =>
      import('./modules/products/products.module').then(
        (m) => m.ProductsModule,
      ),
  },
  {
    path: 'buy',
    loadChildren: () =>
      import('./modules/buy/buy.module').then((m) => m.BuyModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'sell',
    loadChildren: () =>
      import('./modules/sell/sell.module').then((m) => m.SellModule),
  },

  { path: 'terms-and-conditions', loadChildren: () => import('./modules/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule) },


  {
    path: 'register',
    loadChildren: () => import('./modules/users-register/users-register.module').then(m => m.UsersRegisterModule)
  }, */


  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },

  {
    path: '',
    loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule)
  }

];
